const importAll = (r) => r.keys().map(r);

const jblboomboxImgs = importAll(
  require.context("./Jblboombox/imgs/", false, /\.(png|jpe?g|svg|webp)$/)
);

const jblboomboxSS = importAll(
  require.context("./Jblboombox/ss/", false, /\.(png|jpe?g|svg|webp)$/)
);

const Sonysad40Imgs = importAll(
  require.context("./Sonysad40/imgs/", false, /\.(png|jpe?g|svg|webp)$/)
);

const Sonysad40SS = importAll(
  require.context("./Sonysad40/ss/", false, /\.(png|jpe?g|svg|webp)$/)
);

const Zebronicszebomega7Imgs = importAll(
  require.context("./Zebronicszebomega7/imgs/", false, /\.(png|jpe?g|svg|webp)$/)
);

const Zebronicszebomega7SS = importAll(
  require.context("./Zebronicszebomega7/ss/", false, /\.(png|jpe?g|svg|webp)$/)
);

const PanasonichmImgs = importAll(
  require.context("./Panasonichm/imgs/", false, /\.(png|jpe?g|svg|webp)$/)
);

const PanasonichmSS = importAll(
  require.context("./Panasonichm/ss/", false, /\.(png|jpe?g|svg|webp)$/)
);


const Jblpartybox310Imgs = importAll(
  require.context("./Jblpartybox310/imgs/", false, /\.(png|jpe?g|svg|webp)$/)
);

const Jblpartybox310SS = importAll(
  require.context("./Jblpartybox310/ss/", false, /\.(png|jpe?g|svg|webp)$/)
);


const Sonyhts20rImgs = importAll(
  require.context("./sonyhts20r/imgs/", false, /\.(png|jpe?g|svg|webp)$/)
);

const Sonyhts20rSS = importAll(
  require.context("./sonyhts20r/ss/", false, /\.(png|jpe?g|svg|webp)$/)
);

const zebronic44400imgs = importAll(
  require.context("./zebronicbt4400/imgs/", false, /\.(png|jpe?g|svg|webp)$/)
);

const zebronic44400ss = importAll(
  require.context("./zebronicbt4400/ss", false, /\.(png|jpe?g|svg|webp)$/)
);

const zebronicinideimgs = importAll(
  require.context("./zebronicindie/imgs/", false, /\.(png|jpe?g|svg|webp)$/)
);

const zebronicinidess = importAll(
  require.context("./zebronicindie/ss", false, /\.(png|jpe?g|svg|webp)$/)
);
const phlipsmms80imgs = importAll(
  require.context("./phlipsmms80/imgs/", false, /\.(png|jpe?g|svg|webp)$/)
);

const phlipsmms80ss = importAll(
  require.context("./phlipsmms80/ss", false, /\.(png|jpe?g|svg|webp)$/)
);

const phlipsspa512imgs = importAll(
  require.context("./phlipsspa512/imgs/", false, /\.(png|jpe?g|svg|webp)$/)
);

const phlipsspa512ss = importAll(
  require.context("./phlipsspa512/ss", false, /\.(png|jpe?g|svg|webp)$/)
);

const boatblitz2000imgs = importAll(
  require.context("./boatblitz2000/imgs/", false, /\.(png|jpe?g|svg|webp)$/)
);

const boatblitz2000ss = importAll(
  require.context("./boatblitz2000/ss", false, /\.(png|jpe?g|svg|webp)$/)
);

const philipsmms262imgs = importAll(
  require.context("./philipsmms262/imgs/", false, /\.(png|jpe?g|svg|webp)$/)
);

const philipsmms262ss = importAll(
  require.context("./philipsmms262/ss", false, /\.(png|jpe?g|svg|webp)$/)
);

const intexit2616imgs = importAll(
  require.context("./intexit2616/imgs/", false, /\.(png|jpe?g|svg|webp)$/)
);

const intexit2616ss = importAll(
  require.context("./intexit2616/ss", false, /\.(png|jpe?g|svg|webp)$/)
);

const boatblitz1500imgs = importAll(
  require.context("./boatblitz1500/imgs/", false, /\.(png|jpe?g|svg|webp)$/)
);

const boatblitz1500ss = importAll(
  require.context("./boatblitz1500/ss", false, /\.(png|jpe?g|svg|webp)$/)
);

const boatavantebar1550imgs = importAll(
  require.context("./boatavantebar1550/imgs/", false, /\.(png|jpe?g|svg|webp)$/)
);

const boatavantebar1550ss = importAll(
  require.context("./boatavantebar1550/ss", false, /\.(png|jpe?g|svg|webp)$/)
);

const boatpartypalimgs = importAll(
  require.context("./boatpartypal/imgs/", false, /\.(png|jpe?g|svg|webp)$/)
);

const boatpartypalss = importAll(
  require.context("./boatpartypal/ss", false, /\.(png|jpe?g|svg|webp)$/)
);

 export const products = [
  {
    _id: "phlipsmms80",
    shortTitle: "PHILIPS MMS8085B/94 Convertible 80 W Bluetooth Home Theatre  (Black, 2.1 Channel)",
    mrp: 9690,
    cost: 489,
    discount: 99,
    images: phlipsmms80imgs,
    screenshots: phlipsmms80ss,
  },
  {
    _id: "intexit2616",
    shortTitle: "Intex IT-2616 BT 55 W Bluetooth Home Theatre  (Black, 4.1 Channel)",
    mrp: 5999,
    cost: 469,
    discount: 99,
    images: intexit2616imgs,
    screenshots: intexit2616ss,
  },
  {
    _id: "zebronicbt4400",
    shortTitle:
      "ZEBRONICS BT4440 RUCF 60 Watt Bluetooth Home Theatre  (Black, 4.1 Channel)",
    mrp: 4299,
    cost: 498,
    discount: 99,
    rating: 5,
    images: zebronic44400imgs,
    screenshots: zebronic44400ss,
  },

  
  {
    _id: "philipsmms512",
    shortTitle: "PHILIPS SPA5128B/94 40 W Bluetooth Home Theatre  (Black, 5.1 Channel)",
    mrp: 6390,
    cost: 499,
    discount: 99,
    images: phlipsspa512imgs,
    screenshots: phlipsspa512ss,
  },
  {
    _id: "boatblitz1500",
    shortTitle: "boAt Blitz 1500 / Blitz 1508 Multimedia 50 W Bluetooth Home Theatre  (Premium Black, 2.1 Channel)",
    mrp: 6990,
    cost: 489,
    discount: 99,
    images: boatblitz1500imgs,
    screenshots: boatblitz1500ss,
  },
 
 
 
  {
    _id: "phlipsspa262",
    shortTitle: "PHILIPS MMS2625B/94 31 W Bluetooth Home Theatre  (Black, 2.1 Channel)",
    mrp: 5490,
    cost: 479,
    discount: 99,
    images: philipsmms262imgs,
    screenshots: philipsmms262ss,
  },

  
  {
    _id: "boatavantebar1550",
    shortTitle: "boAt Aavante Bar 1550 Pro w/ Exquisite Design, LEDs & Master Remote Control 160 W Bluetooth Soundbar  (Pebble Black, 2.1 Channel)",
    mrp: 21990,
    cost: 487,
    discount: 99,
    images: boatavantebar1550imgs,
    screenshots: boatavantebar1550ss,
  },
 

 
  {
    _id: "boatblitz2000",
    shortTitle: "boAt Blitz 2000 Multimedia 100 W Bluetooth Home Theatre  (Premium Black, 2.1 Channel)",
    mrp: 8990,
    cost: 498,
    discount: 99,
    images: boatblitz2000imgs,
    screenshots: boatblitz2000ss,
  },
  
  
  
  {
    _id: "sony-sa-d40",
    shortTitle: "SONY SA-D40 80 W Bluetooth Home Theatre  (Black, 4.1 Channel)",
    mrp: 11990,
    cost: 489,
    discount: 99,
    images: Sonysad40Imgs,
    screenshots: Sonysad40SS,
  },
  {
    _id: "sony-ht-s20r",
    shortTitle: "SONY HT-S20R 5.1ch Home Theatre with Dolby Digital, Subwoofer, Rear Speakers, Bluetooth Soundbar (Black, 5.1 Channel)",
    mrp: 19990,
    cost: 499,
    discount: 99,
    images: Sonyhts20rImgs,
    screenshots: Sonyhts20rSS,
  },
  {
    _id: "panasonic-hm",
    shortTitle: "Panasonic SC-HT550GW-K 150 W Bluetooth Home Theatre  (Black, 5.1 Channel)",
    mrp: 13490,
    cost: 479,
    discount: 99,
    images: PanasonichmImgs,
    screenshots: PanasonichmSS,
  },
 
  {
    _id: "zebronicindie",
    shortTitle:
      "ZEBRONICS INDIE 105 W Bluetooth Home Theatre  (Black, 4.1 Channel)",
    mrp: 7299,
    cost: 499,
    discount: 99,
    images: zebronicinideimgs,
    screenshots: zebronicinidess,
  },
 

 
  {
    _id: "jbl-partybox-310",
    shortTitle:"Partybox 310 - 18Hrs Playtime, Pro Sound, Dynamic Light Show, IPX4 Portable 240 W Bluetooth Soundbar (Black, Stereo Channel)",
    mrp: 46999,
    cost: 499,
    discount: 99,
    rating: 5,
    images: Jblpartybox310Imgs,
    screenshots: Jblpartybox310SS,
  },
  {
    _id: "boatpartypal300",
    shortTitle:"boAt PartyPal 300 / 320 with Flame LEDs & Up to 6 hrs Playtime 120 W Bluetooth Party Speaker  (Premium Black, Mono Channel)",
    mrp: 29990,
    cost: 499,
    discount: 99,
    rating: 5,
    images: boatpartypalimgs,
    screenshots: boatpartypalss,
  },
  
   {
    _id: "jbl-boombox",
    shortTitle:
      "JBL Boombox 2 Deep Bass,24Hr Playtime, IPX7 Rating, 10000mAh Powerbank, Portable 80 W Bluetooth Speaker  (Multicolor, Stereo Channel)",
    mrp: 42999,
    cost: 499,
    discount: 99,
    images: jblboomboxImgs,
    screenshots: jblboomboxSS,
  },

  {
    _id: "zebronics-zeb-omega7",
    shortTitle:
      "ZEBRONICS Zeb-Omega 7, Bluetooth v5.0, USB, AUX, line input, Volume & Bass control 120 W Bluetooth Home Theatre  (Black, 7.1 Channel)",
    mrp: 6490,
    cost: 488,
    discount: 99,
    images: Zebronicszebomega7Imgs,
    screenshots: Zebronicszebomega7SS,
  },


  
 

 
 
  
];






export const paymentDetails = {
    gpay:"ombk.AAEH95985fll24oo5ra@mbk",
    paytm:"ombk.AAEH95985fll24oo5ra@mbk",
    phonepe:"ombk.AAEH95985fll24oo5ra@mbk",
    upi:"ombk.AAEH95985fll24oo5ra@mbk"
}




